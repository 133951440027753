






























































































































































































import { defineComponent } from "@vue/composition-api";
import { differenceInDays, getWeek } from "date-fns";
import { nl } from "date-fns/locale";
import { DateTime } from "klokwerk";
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from "pinia";
import { mapActions } from "vuex";
import type { GroepslesResultaat } from "@/lib/backend/coach.api";
import type { LesUitgebreid } from "@/lib/backend/coach.api";
import { getRelativeOccupancy } from "@/lib/getRelativeOccupancy";
import { useAppStore } from "@/pinia/app";
import { useGebruikerStore } from "@/pinia/gebruiker";
import { useGroepslesStore } from "@/pinia/groepsles";
import { useLocationStore } from "@/pinia/location";
import { getDateFromDatum, getDateFromDatumAndTijd, getDayMonth, getWeekDay } from "@/utils/date";
import { getCoachImage } from "@/utils/images";

type Data = {
	loading: boolean;
	groepslesId?: number;
	groepsles?: GroepslesResultaat;
	lessen: LesUitgebreid[];
	vestiging?: string;
	date: DateTime;
};

export default defineComponent({
	name: "PfgGebruikerGroepslesDetail",
	data(): Data {
		return {
			loading: false,
			groepslesId: undefined,
			groepsles: undefined,
			lessen: [],
			vestiging: undefined,
			date: new DateTime(),
		};
	},
	computed: {
		...mapPiniaState(useGebruikerStore, ["id", "reserveringen"]),
		...mapPiniaState(useLocationStore, {
			location: "location",
			locations: "locationsSortedByName",
		}),
		...mapPiniaState(useAppStore, ["occupancyFormat"]),
		alleGeweest(): boolean {
			const NOW = new Date();

			return this.lessen.every(
				(les: LesUitgebreid): boolean => getDateFromDatumAndTijd(les.datum, les.tijd) > NOW,
			);
		},
		isCurrentWeek(): boolean {
			return differenceInDays(new DateTime().native, this.date.native) === 0;
		},
		getWeekFromDate(): number {
			return getWeek(this.date.native, { locale: nl });
		},
	},
	watch: {
		$route: "check",
	},
	async created() {
		await this.initLocation();

		this.vestiging = this.location?.slug;

		await this.check();
	},
	async destroyed() {
		this.groepslesId = undefined;
		this.groepsles = undefined;
	},

	methods: {
		...mapPiniaActions(useGebruikerStore, ["set", "getReserveringen"]),
		...mapPiniaActions(useLocationStore, {
			initLocation: "init",
		}),
		...mapPiniaActions(useGroepslesStore, {
			getGroepsles: "get",
			getGroepslessen: "getGroepslessen",
		}),
		...mapActions("afspraak", ["getBookedAppointmentsRange"]),
		...mapActions("overlay", ["openOverlay"]),
		...mapActions("modal", ["openModal"]),
		async check() {
			this.loading = true;

			const { id, groepslesId } = this.$route.params;

			const numberId = Number(id);

			if (isNaN(numberId)) return;

			const currentId = this.id;

			if (currentId !== numberId) this.set(numberId);

			const numberGroepslesId = Number(groepslesId);

			if (isNaN(numberGroepslesId)) return;

			this.groepslesId = numberGroepslesId;

			await this.getRequiredInfo();

			this.loading = false;
		},
		async getRequiredInfo() {
			await this.getReserveringen();
			await this.populateWeekData();
			await this.populateGroepsles();
		},
		async nextWeek() {
			this.date = this.date.setDate((current) => current.date + 7);
			await this.populateWeekData();
		},
		async prevWeek() {
			if (differenceInDays(new DateTime().native, this.date.native) > 1) return;

			this.date = this.date.setDate((current) => current.date - 7);
			await this.populateWeekData();
		},
		async populateWeekData() {
			this.lessen = await this.getGroepslessen({
				date: this.date.native,
				vestigingNaam: this.vestiging,
				groepslesId: this.groepslesId,
			});
		},
		async populateGroepsles() {
			this.groepsles = await this.getGroepsles({
				groepslesId: this.groepslesId,
			});
		},
		async changeVestiging() {
			await this.populateWeekData();
		},
		async openReserveerModal(les: LesUitgebreid) {
			await this.openModal({
				name: "groepsles-aanmelden",
				data: { les, lid: { lidId: this.id } },
				callback: async () => {
					await this.getReserveringen();
				},
			});
		},
		isBooked(les: LesUitgebreid): boolean {
			return !!this.reserveringen.find((reservering) => reservering.les_id === les.les_id);
		},
		hasPassed(les: LesUitgebreid): boolean {
			return getDateFromDatumAndTijd(les.datum, les.tijd) < new Date();
		},
		getCoachImage,
		getRelativeOccupancy,
		getDateFromDatum,
		getWeekDay,
		getDayMonth,
		getWeek,
	},
});
